import axios from 'axios';
import {
    EventBus
} from "@/libraries/EventBus";

export default {
    data() {
        return {
            currentHash: '20241213133102',
            hashChanged: false,
            newHash: ''
        }
    },
    computed: {
        isProd: function () {
            if (process.env.NODE_ENV == "development") {
                return false;
            } else {
                return true;
            }
        },
        frequency: function () {
            if (this.isProd) {
                return 1000 * 60 * 2;
            } else {
                return 1000 * 60 * 2;
            }
        }
    },
    methods: {
        initVersionCheck() {
            if(this.isProd) {
                setInterval(() => {
                    this.checkVersion('/deploy.json');
                }, this.frequency);
            }
        },
        async checkVersion(url) {
            try {
                console.log("Checking version - current: " + this.currentHash);

                let http = axios.create({
                    baseURL: this.isProd ? process.env.VUE_APP_API_URL : 'http://localhost:8080',
                    withCredentials: true,
                    maxContentLength: 999999,
                    timeout: 30000,
                });

                const fileResponse = await http.get(url + '?t=' + new Date().getTime());
                this.newHash = fileResponse.data.deploy;
                this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);

                console.log("Check version response: " + fileResponse.data.deploy);
                console.log("hasChanged = " + this.hashChanged);

                if (this.hashChanged == true) {
                    EventBus.$emit('new-system-version');
                }
            } catch (error) {}
        },
        hasHashChanged(currentHash, newHash) {
            return currentHash !== newHash;
        },
    }
};