<script>
import {mapGetters} from "vuex"; 

export default {
    name: 'DefaultMenu',
    computed: {
      ...mapGetters("auth", ["getAuthUser", "getIsAdmin", "getAclMap"])
    },
    methods: {
      async doLogout() {
        await this.$store.dispatch('auth/logout');
        this.$router.push("/login");
      }
    }
}
</script>

<template>
    <side-bar>

      <template slot="links">
        <!-- -->        
        <sidebar-item :link="{name: $t('menu.registration'), icon: 'far fa-cabinet-filing'}" :force-navigation="{name: 'StartDashboard'}">
            <sidebar-item :link="{ name: $t('menu.my_cases_overview'), path: '/projects/my-overview' }" />
            <sidebar-item :link="{name: $t('menu.my_tasks'), icon: 'far fa-tasks', path: '/tasks/my-tasks'}" v-if="true" />
            <sidebar-item :link="{name: $t('menu.my_travels'), icon: 'far fa-cars', path: '/travels/my-travels'}" v-if="true" />
            <sidebar-item :link="{name: $t('menu.my_hours'), icon: 'far fa-hourglass', path: '/reports/my-hours'}" v-if="true" />                    

        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.development'), icon: 'far fa-calendar-alt'}">
            <sidebar-item :link="{ name: $t('menu.planning_portfolio'), path: '/projects/portfolio' }" />
            <sidebar-item :link="{ name: $t('menu.projects_my_projects'), path: '/projects/portfolio?preset=mine' }" v-if="false"/>
            <sidebar-item :link="{ name: $t('menu.planning_report'), path: '/projects/planning-report' }" />          
            <sidebar-item :link="{ name: $t('menu.data_exports'), path: '/reports/projects-reports' }" v-if="$aclCan($ACL.CUSTOM_PLANNING_EXP_DEVMODEL)" />          
            <sidebar-item :link="{ name: $t('menu.mfa_flags'), path: '/project-flags/index' }" v-if="$aclCan($ACL.CUSTOM_PLANNING_MFA)" />    
            <sidebar-item :link="{ name: $t('menu.mfa_status'), path: '/project-flags/status-mfa' }" v-if="$aclCan($ACL.CUSTOM_PLANNING_MFA)" />    
        </sidebar-item>
        
        <sidebar-item :link="{name: $t('menu.cases'), icon: 'far fa-clipboard-list-check'}">
          <sidebar-item :link="{ name: $t('menu.all_cases'), path: '/projects/index' }" />
          <sidebar-item :link="{ name: $t('menu.create_case'), path: '/projects/index?create=case' }"  v-if="$aclCan($ACL.CUSTOM_PLANNING_CREATE_PROJ)" />
          <sidebar-item :link="{ name: $t('menu.my_cases_overview'), path: '/projects/my-overview' }" />
          <sidebar-item :link="{ name: $t('menu.my_cases_invoices'), path: '/projects/my-invoices' }" />
          <sidebar-item :link="{name: $t('menu.reopen_day'), icon: 'far fa-tasks', path: '/projects/reopen'}" v-if="$aclCan($ACL.TIMEREG_MGMT)" />          
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.administrative'), icon: 'far fa-users', path: '/users/index'}" :hide-mobile="true" v-if="getIsAdmin">
            <sidebar-item :link="{ name: $t('menu.all_customers'), path: '/customers/index' }" />
            <sidebar-item :link="{ name: $t('menu.users'), path: '/users/index' }" :hide-mobile="true" />
            <sidebar-item :link="{ name: $t('menu.departments'), path: '/departments/index' }" :hide-mobile="true" />
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.bookkeepers'), icon: 'far fa-book'}" :hide-mobile="true" v-if="$aclCan($ACL.INVOICE_MGMT)" >
            <sidebar-item :link="{name: $t('menu.travel_viewer'), icon: 'far fa-tasks', path: '/travels/index'}" v-if="$aclCan($ACL.INVOICE_MGMT)" />
            <sidebar-item :link="{name: $t('menu.travel_salary'), icon: 'far fa-tasks', path: '/travels/salary'}" v-if="$aclCan($ACL.INVOICE_MGMT)" />
            <sidebar-item :link="{name: $t('menu.travel_salary_reports'), icon: 'far fa-tasks', path: '/travels/salary-reports'}" v-if="$aclCan($ACL.INVOICE_MGMT)" />
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.reports'), icon: 'far fa-chart-pie'}" :hide-mobile="true" v-if="$aclCan($ACL.STATS_VIEW)" >
          <sidebar-item :link="{name: $t('menu.all_tasks'), icon: 'far fa-tasks', path: '/tasks/index'}" v-if="$aclCan($ACL.TIMEREG_MGMT)" />
          <sidebar-item :link="{name: $t('menu.all_cases_overview'), icon: 'far fa-tasks', path: '/projects/full-overview'}" v-if="$aclCan($ACL.TIMEREG_MGMT)" />
          <sidebar-item :link="{name: $t('menu.projects_invoicing_report'), icon: 'far fa-tasks', path: '/projects/invoicing-report'}" v-if="$aclCan($ACL.TIMEREG_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.hour_report'), path: '/reports/activities' }" />
          <sidebar-item :link="{ name: $t('menu.absence'), path: '/reports/absence' }" v-if="$aclCan($ACL.PERSONAL_REPORT_MGMT)" />
          <sidebar-item :link="{ name: $t('menu.cases'), path: '/reports/projects' }" v-if="$aclCan($ACL.STATS_VIEW)" />
          <sidebar-item :link="{ name: $t('menu.cases_performance'), path: '/reports/projects-performance' }" v-if="$aclCan($ACL.STATS_VIEW)" />
          <sidebar-item :link="{ name: $t('menu.open_days'), path: '/reports/open-days' }" v-if="$aclCan($ACL.STATS_VIEW)" />
          <sidebar-item :link="{ name: $t('menu.contract_reports'), path: '/reports/contracts' }" :hide-mobile="true" v-if="$aclCan($ACL.STATS_VIEW)"  />
          <sidebar-item :link="{ name: $t('menu.personal_multiview'), path: '/reports/personal-multiview' }" :hide-mobile="true" v-if="$aclCan($ACL.PERSONAL_REPORT_MGMT)"  />
          <sidebar-item :link="{ name: $t('menu.budget'), path: '/reports/budget' }" :hide-mobile="true" v-if="$aclCan($ACL.STATS_VIEW)"  />
          
        </sidebar-item>

        <!-- -->
        <sidebar-item :link="{name: $t('menu.settings'), icon: 'far fa-cogs', path: '/settings'}" v-if="$aclCan($ACL.TIMEREG_MGMT)">
          <sidebar-item :link="{ name: $t('menu.activities'), path: '/settings/activities' }" />
          <sidebar-item :link="{ name: $t('menu.milestones'), path: '/settings/milestones' }" />
          <sidebar-item :link="{ name: $t('menu.role_prices'), path: '/settings/role-prices' }" />
          <sidebar-item :link="{ name: $t('menu.travel_prices'), path: '/travel-prices' }" />
        </sidebar-item>

        <sidebar-item :link="{name: $t('menu.help'), icon: 'far fa-question-circle', path: '/help'}">
        </sidebar-item>

        <a class="nav-link" href="#" @click.prevent="doLogout">
          <i class="far fa-sign-out"></i>
          <span class="nav-link-text">{{$t('menu.logout')}}</span>
        </a>

      </template>
    </side-bar>
</template>
