<script>
export default {
    props: {
        m: {}
    }
};
</script>

<template>
    <span class="v-m-milestone-mini badge badge-primary">M{{ m.m_ident }}</span>
</template>

<style lang="scss">
.v-m-milestone-mini {
    font-size: 12px !important;
    font-weight: bold !important;
}
</style>
