<script>
/**
 * Documentation: https://www.npmjs.com/package/vue2-datepicker
 */
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/da";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
dayjs.extend(isoWeek);

export default {
    props: {
        context: {
            type: Object,
            required: true
        },
        version: {
            type: String,
            default: "start"
        }
    },
    components: { DatePicker },
    methods: {
        filterDate(sel, current) {
            if (this.version == "start") {
                if (dayjs(sel).day() == 1) {
                    return false;
                } else {
                    return true;
                }
            } else {
                if (dayjs(sel).day() == 5) {
                    return false;
                } else {
                    return true;
                }
            }
            console.log("Filter date", sel, current);
        }
    }
};
</script>

<template>
    <div
        :class="
            `formulate-input-element formulate-datepicker formulate-input-element--${context.type} formulate-date-picker-custom gantt-picker-master`
        "
        :data-type="context.type"
    >
        <date-picker
            type="week"
            format="DD.MM.YYYY"
            value-type="YYYY-MM-DD"
            v-model="context.model"
            input-class="form-control"
            :editable="true"
            :disabled-date="filterDate"
            :clearable="false"
            :disabled="context.attributes.disabled"
        />
    </div>
</template>

<style lang="scss">
.formulate-datepicker {
    .mx-datepicker {
        width: 100% !important;
    }
}
</style>
