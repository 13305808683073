import BaseInput from "@/components/Inputs/BaseInput.vue";
import BaseDropdown from "@/components/BaseDropdown.vue";
import Card from "@/components/Cards/Card.vue";
import Modal from "@/components/Modal.vue";
import StatsCard from "@/components/Cards/StatsCard.vue";
import VStatsCard from "@/components/Cards/VStatsCard.vue";
import BaseButton from "@/components/BaseButton.vue";
import Badge from "@/components/Badge.vue";
import RouteBreadcrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import BaseCheckbox from "@/components/Inputs/BaseCheckbox.vue";
import BaseSwitch from "@/components/BaseSwitch.vue";
import BaseRadio from "@/components/Inputs/BaseRadio";
import BaseProgress from "@/components/BaseProgress";
import BasePagination from "@/components/BasePagination";
import BaseAlert from "@/components/BaseAlert";
import BaseNav from "@/components/Navbar/BaseNav";
import BaseHeader from "@/components/BaseHeader";
import BreadBag from "@/components/Breadcrumb/BreadBag";
import VTableBody from "@/components/Table/VTableBody";
import VTableData from "@/components/Table/VTableData";
import VTableHeadData from "@/components/Table/VTableHeadData";
import VTableHead from "@/components/Table/VTableHead";
import VTableRow from "@/components/Table/VTableRow";
import VTableFoot from "@/components/Table/VTableFoot";
import VTable from "@/components/Table/VTable";
import VSorter from "@/components/Table/VSorter";
import VCustomerGoto from "@/components/VCustomerGoto";
import NavStatsCard from "@/components/Cards/NavStatsCard";
import VLoader from "@/components/VLoader";
import VLoaderModal from "@/components/VLoaderModal";
import VWip from "@/components/VWip";
import VEmpty from "@/components/VEmpty";
import VErrors from "@/components/VErrors";
import VEntityErrors from "@/components/VEntityErrors";
import VQueue from "@/components/VQueue";
import FlagIcon from "vue-flag-icon";
import LangIcon from "@/components/LangIcon";
import VNewlines from "@/components/VNewlines";

import ReadMore from "vue-read-more";
import VueCroppie from "vue-croppie";
import "croppie/croppie.css"; // import the croppie css manually

import VAvatar from "@/components/VAvatar";

import { ValidationProvider, ValidationObserver } from "vee-validate";
import { Input, Tooltip, Popover } from "element-ui";

//import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { setupCalendar, DatePicker, Calendar } from "v-calendar";
import { VueRenderer } from "@tiptap/vue-2";
import VM from "@/components/Planning/VM";

setupCalendar({
    locale: "da"
});

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 */

const GlobalComponents = {
    install(Vue) {
        Vue.component(Badge.name, Badge);
        Vue.component(BaseAlert.name, BaseAlert);
        Vue.component(BaseButton.name, BaseButton);
        Vue.component(BaseCheckbox.name, BaseCheckbox);
        Vue.component(BaseHeader.name, BaseHeader);
        Vue.component(BaseInput.name, BaseInput);
        Vue.component(BaseDropdown.name, BaseDropdown);
        Vue.component(BaseNav.name, BaseNav);
        Vue.component(BasePagination.name, BasePagination);
        Vue.component(BaseProgress.name, BaseProgress);
        Vue.component(BaseRadio.name, BaseRadio);
        Vue.component(BaseSwitch.name, BaseSwitch);
        Vue.component(Card.name, Card);
        Vue.component(Modal.name, Modal);
        Vue.component(StatsCard.name, StatsCard);
        Vue.component(RouteBreadcrumb.name, RouteBreadcrumb);
        Vue.component(Input.name, Input);
        Vue.component("validation-provider", ValidationProvider);
        Vue.component("validation-observer", ValidationObserver);
        // Own
        Vue.component(BreadBag.name, BreadBag);
        Vue.component(VStatsCard.name, VStatsCard);
        Vue.component(VEmpty.name, VEmpty);
        // Tables
        Vue.component(VTableBody.name, VTableBody);
        Vue.component(VTableData.name, VTableData);
        Vue.component(VTableHeadData.name, VTableHeadData);
        Vue.component(VTableHead.name, VTableHead);
        Vue.component(VTableRow.name, VTableRow);
        Vue.component(VTableFoot.name, VTableFoot);
        Vue.component(VTable.name, VTable);

        Vue.component(VCustomerGoto.name, VCustomerGoto);
        Vue.component(NavStatsCard.name, NavStatsCard);
        Vue.component(VLoader.name, VLoader);
        Vue.component(VLoaderModal.name, VLoaderModal);

        Vue.component(VSorter.name, VSorter);

        Vue.component(VWip.name, VWip);
        Vue.component(VErrors.name, VErrors);
        Vue.component(VQueue.name, VQueue);
        Vue.component(VEntityErrors.name, VEntityErrors);
        Vue.use(FlagIcon);
        Vue.component("LangIcon", LangIcon);
        // Rest
        Vue.use(Tooltip);
        Vue.component(Popover.name, Popover);
        //    Vue.use(Popover);
        Vue.use(ReadMore);
        Vue.use(VueCroppie);
        Vue.component("VAvatar", VAvatar);
        Vue.component("date-picker", DatePicker);
        Vue.component("v-calendar", Calendar);

        Vue.component(VNewlines.name, VNewlines);
        Vue.component("VM", VM);
    }
};

export default GlobalComponents;
