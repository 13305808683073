import DefaultLayout from "@/views/Layout/DefaultLayout";
import i18n from "@/i18n.js";

const projectFlagsMenu = {
    path: "/project-flags",
    component: DefaultLayout,
    meta: {
        title: i18n.t("title.project_flags")
    },
    children: [
        {
            path: "index",
            name: "ProjectFlagsIndex",
            component: () =>
                import(
                    /* webpackChunkName: "project-flags" */ "@/views/ProjectFlags/VIndex"
                ),
            meta: {
                title: i18n.t("title.project_flags_index")
            }
        },
        {
            path: "status-mfa",
            name: "ProjectStatusMfa",
            component: () =>
                import(
                    /* webpackChunkName: "project-flags" */ "@/views/ProjectFlags/VStatuses"
                ),
            meta: {
                title: i18n.t("title.project_milestone_status_changes")
            }
        }
    ]
};

export default projectFlagsMenu;
