/**
 * This module is for general UI settings/preferences
 *
 */
// data()
const state = () => ({
    // Show/hide navstats in VProjectView
    currentContextMenu: null,

    // MyTasks filtering prefs
    defaultMyTasksDeadlineFilter: null,
    defaultMyTasksPriorityFilter: null,
    defaultMyTasksFView: null,

    // Projects filtering prefs
    defaultProjectsDeadlineFilter: null,
    defaultProjectsPriorityFilter: null,
    defaultProjectsFView: null,

    // Right menu planning
    rightMenuShow: false
});

// computed()
const getters = {
    getCurrentContextMenu(state) {
        return state.currentContextMenu;
    },
    showRightMenu(state) {
        return state.rightMenuShow;
    }
};

// methods -> dispatch()
const actions = {};

// change store -> commit()
const mutations = {
    setRightMenu(state, payload) {
        state.rightMenuShow = payload;
    },
    setCurrentContextMenu(state, payload) {
        state.currentContextMenu = payload;
    },
    // MyTasks
    initializeMyTasks(state) {
        console.log("Initialize store with data MYTASKS");
        state.defaultMyTasksDeadlineFilter = localStorage.getItem(
            "defaultMyTasksDeadlineFilter"
        );
        state.defaultMyTasksPriorityFilter = localStorage.getItem(
            "defaultMyTasksPriorityFilter"
        );
        state.defaultMyTasksFView = localStorage.getItem("defaultMyTasksFView");
    },
    setDefaultMyTasksDeadlineFilter(state, payload) {
        console.log("UI TEST", state, payload);
        state.defaultMyTasksDeadlineFilter = payload;
        localStorage.setItem("defaultMyTasksDeadlineFilter", payload);
    },
    setDefaultMyTasksPriorityFilter(state, payload) {
        state.defaultMyTasksPriorityFilter = payload;
        localStorage.setItem("defaultMyTasksPriorityFilter", payload);
    },
    setDefaultMyTasksFView(state, payload) {
        state.defaultMyTasksFView = payload;
        localStorage.setItem("defaultMyTasksFView", payload);
    },
    // Projects
    initializeProjects(state) {
        console.log("Initialize store with data PROJECTS");
        state.defaultProjectsDeadlineFilter = localStorage.getItem(
            "defaultProjectsDeadlineFilter"
        );
        state.defaultProjectsPriorityFilter = localStorage.getItem(
            "defaultProjectsPriorityFilter"
        );
        state.defaultProjectsFView = localStorage.getItem(
            "defaultProjectsFView"
        );
    },
    setDefaultProjectsDeadlineFilter(state, payload) {
        state.defaultProjectsDeadlineFilter = payload;
        localStorage.setItem("defaultProjectsDeadlineFilter", payload);
    },
    setDefaultProjectsPriorityFilter(state, payload) {
        state.defaultProjectsPriorityFilter = payload;
        localStorage.setItem("defaultProjectsPriorityFilter", payload);
    },
    setDefaultProjectsFView(state, payload) {
        state.defaultProjectsFView = payload;
        localStorage.setItem("defaultProjectsFView", payload);
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
