/**
 * Roles:
    - DEFAULT
    - USER
    - SUPERUSER
    - LEADER
    - ROOT
 */
export default {
    data: function() {
        return {
            ACL_RW: true
        };
    },
    created() {
        this.$ACL = {
            USER_MGMT: "user_mgmt",
            CONTRACT_MGMT: "contract_mgmt",
            INVOICE_MGMT: "invoice_mgmt",
            TIMEREG_MGMT: "timereg_mgmt",
            TIMEREG_USAGE: "timereg_usage",
            STATS_VIEW: "stats_view",
            OFFERS_MGMT: "offers_mgmt",
            CUSTOMER_EDIT: "customer_edit",
            PERSONAL_REPORT_MGMT: "personal_report_mgmt",
            // custom rules
            CUSTOM_GRANT: "custom_grant",
            CUSTOM_TEST_RIGHT: "custom_test_right",
            CUSTOM_PLANNING_MFA: "custom_planning_mfa",
            CUSTOM_CREATE_USERS: "custom_create_users",
            CUSTOM_EMP_COST: "custom_emp_cost",
            CUSTOM_PLANNING_EXP_DEVMODEL: "custom_planning_exp_devmodel",
            CUSTOM_PLANNING_CREATE_PROJ: "custom_planning_create_proj",
            CUSTOM_PLANNING_BK: "custom_planning_bk"
        };
    },
    methods: {
        $aclGuardOR(required_role, ORCondition) {
            let aclResult = this.$aclCan(required_role);

            if (!aclResult && !ORCondition) {
                this.$notify({
                    message: this.$t("acl.notify_no_access_to_a_given_page"),
                    timeout: 5000,
                    icon: "far fa-ban",
                    type: "danger"
                });
                this.$router.push({ name: "ErrorAccessDenied" });
            }

            return aclResult || ORCondition;
        },
        $aclGuard(required_role) {
            let aclResult = this.$aclCan(required_role);

            if (!aclResult) {
                this.$notify({
                    message: this.$t("acl.notify_no_access_to_a_given_page"),
                    timeout: 5000,
                    icon: "far fa-ban",
                    type: "danger"
                });
                this.$router.push({ name: "ErrorAccessDenied" });
            }

            return aclResult;
        },
        /**
         * Check a capability of the current user against an `action` capability.
         * @param {*} action
         * @returns
         */
        $aclCan(action) {
            let actionMap = this.$store.getters["auth/getAclMap"];
            let result = actionMap.includes(action);
            //console.log("$aclCan --> " + action + " :: " + (result ? "YES" : "NO"));
            return result;
        },
        /**
         *
         * @param {*} tested_user_id user_id on the object that is being tested against
         * @returns
         */
        $aclMeCan(tested_user_id) {
            let user_id = this.$store.getters["auth/getMyId"];

            if (tested_user_id == user_id) {
                return true;
            } else {
                return false;
            }
        }
    }
};
